import type {ButtonGroupContentProps} from '@/components/base/modules/ButtonGroup/ButtonGroup';
import useSignup, {useSignupParser} from '@/hooks/useSignup';
import type {SignupComponent} from '@/types';

export default function useCTAs(
  signupData: SignupComponent,
  ctas?: ButtonGroupContentProps,
): ButtonGroupContentProps | null {
  const {signupUrl} = useSignup();
  const signup = useSignupParser(signupData);

  const buttons = ctas
    ? ctas.buttons
    : [{href: signupUrl, text: signup.buttonText}];

  const disclaimerHtml = ctas ? ctas.disclaimerHtml : signup.disclaimerHtml;

  return {
    buttons: buttons,
    disclaimerHtml: disclaimerHtml,
  };
}
